<template>
  <v-container class="pt-0 pb-15">
    <div style="display: flex; flex-drection: row; align-items: center;">
      <span class="agency-title">Profile</span>
      <div class="title-line ml-3" />
    </div>
    <v-row class="mt-3">
      <v-col
        style="display: flex; flex-direction: column; border-right: 1px solid lightgray;"
        cols="12"
        md="6"
      >
        <span class="subtitle mb-6">Basic Information</span>
        <editable-avatar
          :image="profile.midThumbnail ? profile.midThumbnail : profile.image"
          :onImageSelected="onImageSelected"
        />
        <div>
          <v-row class="mt-3">
            <v-col cols="6">
              <v-text-field
                hide-details
                label="First Name"
                dense
                rounded
                filled
                v-model="profile.first_name"
                background-color="white"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                hide-details
                label="Second Name"
                filled
                v-model="profile.last_name"
                background-color="white"
                dense
                rounded
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                hide-details
                label="Email"
                type="email"
                dense
                rounded
                filled
                background-color="white"
                v-model="profile.email"
                :rules="[rules.required, rules.email]"
              />
            </v-col>
            <v-col cols="8">
              <AppPhoneNumberInput
                v-model="profile.phone"
                @update="onUpdatePhone"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                label="Ext"
                dense
                rounded
                filled
                v-model="profile.phone_ext"
                background-color="white"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                hide-details
                label="Address"
                dense
                rounded
                filled
                v-model="profile.address"
                background-color="white"
                :rules="[rules.required, rules.phone]"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                hide-details
                label="Address 2"
                dense
                rounded
                filled
                v-model="profile.address2"
                background-color="white"
                :rules="[rules.required, rules.phone]"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                label="City"
                dense
                rounded
                filled
                v-model="profile.city"
                background-color="white"
                :rules="[rules.required, rules.phone]"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                hide-details
                :items="stateOptions"
                label="State"
                dense
                rounded
                filled
                v-model="profile.state"
                background-color="white"
                :rules="[rules.required, rules.phone]"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                label="Zip"
                dense
                rounded
                filled
                v-model="profile.zip"
                background-color="white"
                :rules="[rules.required, rules.phone]"
                type="text"
                autocomplete="off"
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                rounded
                color="#472583"
                class="px-15"
                dark
                @click="updateProfile"
              >
                Update Basic Information
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col
        style="display: flex; flex-direction: column;"
        cols="12"
        md="6"
        class="pl-3"
      >
        <div>
          <span class="subtitle mb-6 ma-3">Change Password</span>
          <div class="mt-3">
            <v-text-field
              hide-details
              label="Current Password"
              type="password"
              dense
              rounded
              filled
              background-color="white"
              autocomplete="new-password"
              :rules="[rules.required, rules.min, rules.passwordMatch]"
            />
          </div>
          <div class="mt-3">
            <v-text-field
              hide-details
              label="New Password"
              type="password"
              dense
              rounded
              filled
              background-color="white"
              autocomplete="new-password"
              :rules="[rules.required, rules.min, rules.passwordMatch]"
            />
          </div>
          <div class="mt-3">
            <v-text-field
              hide-details
              label="Confirm Password"
              type="password"
              dense
              rounded
              filled
              background-color="white"
              autocomplete="new-password"
              :rules="[rules.required, rules.min, rules.passwordMatch]"
            />
          </div>
          <div style="display:flex; justify-content: flex-end">
            <v-btn rounded color="#472583" class="mt-5" dark>
              Update Password
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    <v-overlay :value="loading">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "../../components/EditableAvatar.vue";
import AppPhoneNumberInput from "../../components/common/AppPhoneNumberInput.vue";

export default {
  components: { EditableAvatar, AppPhoneNumberInput },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 6) || "Min 6 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI",
      ],
      phonePayload: {},
      loading: false,
      selectedFile: null,
      errorMessage: null,
      snackbar: false,
    };
  },
  methods: {
    ...mapActions("auth", {
      fetchProfile: "fetchProfile",
      updateAdmin: "updateAdmin",
    }),
    onUpdatePhone(payload) {
      this.phonePayload = payload;
    },
    onSelectEvent(ad) {
      console.log(ad);
    },
    updateProfile() {
      var formData = new FormData();
      this.loading = true;
      Object.keys(this.profile).map((key) => {
        if (this.profile[key] != undefined)
          formData.append(key, this.profile[key]);
      });
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      this.updateAdmin(formData)
        .then(() => (this.loading = false))
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  created() {
    this.loading = true;
    this.fetchProfile()
      .then(() => (this.loading = false))
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style scoped>
.agency-title {
  color: #472583;
  font-size: 30px;
  font-weight: bold;
  width: 120px;
  height: 40px;
  min-height: 40px;
  display: flex;
  padding-top: 10px;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.product-title {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.category-title {
  font-size: 32px;
  color: #472583;
  font-weight: 500;
}
.subtitle {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
.v-text-field--filled >>> .v-input__control {
  border-color: #4725835b;
  border-width: 2px;
  border-style: solid;
}
</style>
